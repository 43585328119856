<template>
  <div v-if="selectedOrder">
    <PrintOrderDefault :order="selectedOrder" :printSubPage="true" :printTemplate="printTemplate" :key="orderId"></PrintOrderDefault>
  </div>
</template>

<script>
import PrintOrderDefault from "./Labels/LabelDefault";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PrintEpacketLabel',
  components: {PrintOrderDefault},
  computed: {
    ...mapGetters({
      printTemplateDefault: 'service/printTemplateDefault',
      orderById: 'order/getById',
    }),
    selectedOrder() {
      return this.orderById(parseInt(this.orderId))
    },
    printTemplate() {
      return JSON.parse('{"1":"Default","2":"Default","3":"Default","4":"Default","5":"Default","6":"Default"}')
    }
  },
  methods: {
    ...mapActions({
      getOrderByIdWithoutAuth: 'order/getByIdWithoutAuth',
    }),
    async initOrder() {
      this.orderId = this.$router.currentRoute.params.orderId;
      this.secretKey = this.$router.currentRoute.params.secretKey;
      if(!this.orderId) return;
      await this.getOrderByIdWithoutAuth({
        id: this.orderId,
        secretKey: this.secretKey,
        params: {
          searchWith: 'trackingData;customer',
        }
      });
      await new Promise(resolve => setTimeout(resolve, 100));
      this.$forceUpdate()
    }
  },
  data: () => ({
    orderId: null,
    secretKey: ''
  }),
  created() {
    this.initOrder();
  },
}
</script>
